<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <a-drawer :title="title" width="50%" :closable="false" :visible="visible" @close="onClose(false)" v-if="visible">
    <div>
      <a-table :columns="[sortColumn,...columns]" :row-key="record => record.id" :data-source="listData" :loading="loading" @change="handleTableChange">
        <template #sort="{ index }">
          {{index+1}}
        </template>

        <template #operation="{ record }">
          <span>
            <a-radio-group v-model:value="record.authType" @change="changeAuth(record)">
              <a-radio-button :value="0">个人</a-radio-button>
              <a-radio-button :value="1">本部门</a-radio-button>
              <a-radio-button :value="2">一级部门</a-radio-button>
              <a-radio-button :value="9">全部</a-radio-button>
            </a-radio-group>
          </span>
        </template>
      </a-table>
    </div>
    <div :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#ffffff',
        textAlign: 'right',
        zIndex: 1,
      }">
      <a-button style="margin-right: 8px" @click="onClose(false)">关闭</a-button>
      <a-button type="primary" @click="handleOk">提交</a-button>
    </div>
  </a-drawer>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { dataAuth, updateDataAuth } from '@/api/system/employee'
import { message } from 'ant-design-vue'
export default {
  computed: {
  },

  setup () {
    const state = reactive({
      listData: [],
      empId: '',
      form: {},
      authValue: '',
      visible: false,
      loading: false,
      sortColumn: { title: '序号', slots: { customRender: 'sort' } },
      columns: [
        {
          title: '名称',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 400,
          slots: {
            customRender: 'operation'
          }
        }
      ]
    })

    const loadData = async (id) => {
      state.empId = id
      state.loading = true
      dataAuth(id).then((res) => {
        state.listData = res.data
      })
      state.loading = false
    }
    const onClose = (recode) => {
      state.visible = recode
    }
    const checkTree = (selectedKeys, e) => {
      state.selectedKeys = selectedKeys
    }
    const handleOk = () => {
      // 保存当前人员与资源的权限关系
    }

    const changeAuth = (recode) => {
      state.form = {
        id: recode.authId,
        resouceId: recode.id,
        empId: state.empId,
        authType: recode.authType
      }
      // 保存单次权限关系
      updateDataAuth(state.empId, state.form).then((res) => {
        if (res.code === 10000) {
          message.success('更新数据权限成功')
          if (recode.authId === null || recode.authId === '') {
            recode.authId = res.data
          }
        }
      })
    }

    return {
      loadData,
      ...toRefs(state),
      onclick,
      onClose,
      checkTree,
      handleOk,
      changeAuth
    }
  }
}
</script>
